import Vue from "vue";
import Vuex from "vuex";

import appRolesModule from "./appRoles";
import congregationsMinistersModule from "./congregationsMinisters";
import congregationsModule from "./congregations";
import countriesModule from "./countries";
import courtesyCommitteeMembersModule from "./courtesyCommitteeMembers";
import instructorPreparatoryClassDetailsModule from "./instructorPreparatoryClassDetails";
import matchReplacementUnitApplicationsModule from "./matchReplacementUnitApplications";
import peopleListModule from "./peopleList";
import peopleModule from "./people";
import peopleNamesModule from "./peopleNames";
import pinnedUnitApplicationPlacementsModule from "./pinnedUnitApplicationPlacements";
import unitApplicationPlacementWarningsModule from "./unitApplicationPlacementWarnings";
import unitApplicationCancelationSpotsModule from "./unitApplicationCancelationSpots";
import pinnedUnitApplicationsModule from "./pinnedUnitApplications";
import preparatoryClassApplicationsModule from "./preparatoryClassApplications";
import preparatoryClassInstructorsModule from "./preparatoryClassInstructors";
import preparatoryClassSeasonsModule from "./preparatoryClassSeasons";
import preparatoryClassStatisticsModule from "./preparatoryClassStatistics";
import preparatoryClassesModule from "./preparatoryClasses";
import sidebarMenuModule from "./sidebarMenu";
import statesProvincesModule from "./states";
import submittedPreparatoryClassApplicationsModule from "./submittedPreparatoryClassApplications";
import submittedUnitApplicationsModule from "./submittedUnitApplications";
import textDocumentsModule from "./textDocuments";
import unitApplicationPlacementsForDisplayModule from "./unitApplicationPlacementsForDisplay";
import unitApplicationsModule from "./unitApplications";
import unitPositionTermLengthsModule from "./unitPositionTermLengths";
import unitsModule from "./units";
import userModule from "./user";
import userSettingsModule from "./userSettings";

Vue.use(Vuex);

export const enum StoreActions {
  setCurrentPreparatoryClassSeason = "setCurrentPreparatoryClassSeason",

  loadSessionData = "loadSessionData",

  loadAppRoles = "loadAppRoles",

  loadFirstNames = "loadFirstNames",
  loadMiddleNames = "loadMiddleNames",
  loadLastNames = "loadLastNames",
  loadPeopleMales = "loadPeopleMales",
  loadPeopleFemales = "loadPeopleFemales",
  loadCongregationsMinisters = "loadCongregationsMinisters",

  loadPerson = "loadPerson",
  loadPeople = "loadPeople",

  loadCountries = "loadCountries",
  loadCountry = "loadCountry",

  loadStatesProvinces = "loadStatesProvinces",
  loadStateProvince = "loadStateProvince",

  loadCongregations = "loadCongregations",
  loadCongregation = "loadCongregation",

  loadPreparatoryClasses = "loadPreparatoryClasses",
  loadPreparatoryClass = "loadPreparatoryClass",

  loadUnits = "loadUnits",
  loadUnit = "loadUnit",

  loadUnitPositionTermLengths = "loadUnitPositionTermLengths",
  loadUnitPositionTermLength = "loadUnitPositionTermLength",

  loadPreparatoryClassInstructors = "loadPreparatoryClassInstructors",
  loadPreparatoryClassInstructor = "loadPreparatoryClassInstructor",

  loadTextDocuments = "loadTextDocuments",
  loadTextDocument = "loadTextDocument",

  loadInstructorPreparatoryClassDetails = "loadInstructorPreparatoryClassDetails",
  loadCourtesyCommitteeMembers = "loadCourtesyCommitteeMembers",

  loadPreparatoryClassSeasons = "loadPreparatoryClassSeasons",
  loadPreparatoryClassSeason = "loadPreparatoryClassSeason",

  loadSubmittedPreparatoryClassApplications = "loadSubmittedPreparatoryClassApplications",
  loadSubmittedPreparatoryClassApplication = "loadSubmittedPreparatoryClassApplication",

  loadSubmittedUnitApplications = "loadSubmittedUnitApplications",
  loadSubmittedUnitApplication = "loadSubmittedUnitApplication",

  loadPreparatoryClassApplications = "loadPreparatoryClassApplications",
  loadPreparatoryClassApplication = "loadPreparatoryClassApplication",

  loadUnitApplications = "loadUnitApplications",
  loadUnitApplication = "loadUnitApplication",

  loadPinnedUnitApplications = "loadPinnedUnitApplications",
  loadPinnedUnitApplication = "loadPinnedUnitApplication",

  loadUnitApplicationPlacementWarnings = "loadUnitApplicationPlacementWarnings",
  loadUnitApplicationCancelationSpots = "loadUnitApplicationCancelationSpots",
  loadUnitApplicationCancelationSpot = "loadUnitApplicationCancelationSpot",

  loadMatchReplacementUnitApplications = "loadMatchReplacementUnitApplications",
  loadMatchReplacementUnitApplication = "loadMatchReplacementUnitApplication",

  loadPinnedUnitApplicationPlacements = "loadPinnedUnitApplicationPlacements",
  loadPinnedUnitApplicationPlacement = "loadPinnedUnitApplicationPlacement",

  loadUnitApplicationPlacementsForDisplay = "loadUnitApplicationPlacementsForDisplay",
  loadUnitApplicationPlacementForDisplay = "loadUnitApplicationPlacementForDisplay",
  loadUnitApplicationReplacementForDisplay = "loadUnitApplicationReplacementForDisplay",
  loadUnitApplicationReplacesForDisplay = "loadUnitApplicationReplacesForDisplay",
  loadUnitApplicationsForPlacement = "loadUnitApplicationsForPlacement",
  loadOtherPeopleCongregationIds = "loadOtherPeopleCongregationIds",

  loadPreparatoryClassStatistics = "loadPreparatoryClassStatistics",

  setSmMode = "setSmMode",
}

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appRolesModule,
    congregationsMinistersModule,
    congregationsModule,
    countriesModule,
    courtesyCommitteeMembersModule,
    instructorPreparatoryClassDetailsModule,
    matchReplacementUnitApplicationsModule,
    peopleListModule,
    peopleModule,
    peopleNamesModule,
    unitApplicationPlacementWarningsModule,
    unitApplicationCancelationSpotsModule,
    pinnedUnitApplicationPlacementsModule,
    pinnedUnitApplicationsModule,
    preparatoryClassApplicationsModule,
    preparatoryClassInstructorsModule,
    preparatoryClassSeasonsModule,
    preparatoryClassStatisticsModule,
    preparatoryClassesModule,
    sidebarMenuModule,
    statesProvincesModule,
    submittedPreparatoryClassApplicationsModule,
    submittedUnitApplicationsModule,
    textDocumentsModule,
    unitApplicationPlacementsForDisplayModule,
    unitApplicationsModule,
    unitPositionTermLengthsModule,
    unitsModule,
    userModule,
    userSettingsModule,
  },
});
