import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import {
  unitApplicationPlacementWarningsController,
  UnitApplicationPlacementWarning,
} from "@/controllers/unitApplicationPlacementWarnings";
import { NamedRts } from "@/router";
import { PersonUnitDataItem } from "@/components/PersonUnitData/person-unit-data";

export default defineComponent({
  name: "uapWarnings",
  props: {
    unitId: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const warnings = computed(() => {
      return $store.getters
        .unitApplicationPlacementWarnings as UnitApplicationPlacementWarning[];
    });

    const unitRoute = (w: UnitApplicationPlacementWarning) => {
      return {
        name: NamedRts.unitDashboardPlacementList,
        params: {
          unitId: w.unitId.toString(),
        },
      };
    };

    const unitApplicationPlacementRoute = (
      w: UnitApplicationPlacementWarning,
    ) => {
      return {
        name: NamedRts.personUnitData,
        params: {
          itemType: PersonUnitDataItem.unitApplicationPlacement,
          itemId: w.id,
        },
      };
    };

    onMounted(() => {
      unitApplicationPlacementWarningsController
        .needUnitApplicationPlacementWarnings({ unit_id: Number(props.unitId) })
        .then(() => {
          dataLoaded.value = true;
        });
    });

    return {
      warnings,
      dataLoaded,

      unitRoute,
      unitApplicationPlacementRoute,
    };
  },
});
