import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";

import {
  unitApplicationCancelationSpotController,
  UnitApplicationCancelationSpot,
  emptyUnitApplicationCancelationSpot,
} from "@/controllers/unitApplicationCancelationSpots";
import { TOAST_OPTIONS } from "@/constants";

export default defineComponent({
  name: "unitApplicationCancelationSpot",
  props: {
    unitApplicationId: {
      type: Number,
      required: true,
    },
    unitId: {
      type: Number,
      required: true,
    },
  },

  setup(props, ctx) {
    const dataLoaded = ref(false);
    const cancelationSpots = ref<UnitApplicationCancelationSpot[]>([]);

    const loadData = () => {
      unitApplicationCancelationSpotController
        .needUnitApplicationCancelationSpots({
          unit_application_id: props.unitApplicationId,
        })
        .then((items: UnitApplicationCancelationSpot[]) => {
          // filter out the ones that aren't for this unit
          cancelationSpots.value = items.filter(item => {
            return item.unitId === props.unitId;
          });
        })
        .finally(() => {
          dataLoaded.value = true;
        });
    };

    onMounted(() => {
      loadData();
    });

    const isApplicationPinned = (): boolean => {
      return cancelationSpots.value.length > 0;
    };

    const buttonText = computed(() => {
      if (isApplicationPinned()) {
        return "Remove from Cancelation List";
      } else {
        return "Add to Cancelation List";
      }
    });

    const toggleApplication = () => {
      if (isApplicationPinned()) {
        unitApplicationCancelationSpotController
          .deleteUnitApplicationCancelationSpot(cancelationSpots.value[0])
          .then(() => {
            loadData();
            ctx.root.$bvToast.toast(
              "Application removed from list.",
              TOAST_OPTIONS,
            );
          });
      } else {
        const tmpEmptyCancelationSpot = emptyUnitApplicationCancelationSpot();
        unitApplicationCancelationSpotController
          .createUnitApplicationCancelationSpot({
            ...tmpEmptyCancelationSpot,
            unitId: props.unitId,
            unitApplicationId: props.unitApplicationId,
          })
          .then(() => {
            loadData();
            ctx.root.$bvToast.toast(
              "Application added to cancelation list.",
              TOAST_OPTIONS,
            );
          });
      }
    };

    return {
      dataLoaded,
      buttonText,
      toggleApplication,
    };
  },
});
