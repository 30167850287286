import { defineComponent, computed } from "@vue/composition-api";
import parseISO from "date-fns/parseISO";
import Tel from "@/components/Tel/Tel.vue";
import MailTo from "@/components/MailTo/MailTo.vue";
import {
  appendYearsOld,
  personAgeToday,
  friendlyDate,
  prettifyQualification,
  prettifyGender,
  prettifyMaritalStatus,
} from "@/utilities/displayHelpers";
import { person } from "@/controllers/personDashboard";

export default defineComponent({
  name: "personDashboardPersonShow",
  components: {
    Tel,
    MailTo,
  },
  props: {
    personId: {
      type: String,
      required: true,
    },
  },

  setup(_props, ctx) {
    const prettifiedBirthPlace = computed(() => {
      if (person.value.birthCity !== "") {
        return `${person.value.birthCity}, ${person.value.birthStateName}`;
      } else {
        return person.value.birthStateName;
      }
    });

    const localPersonAge = () => {
      const birthDate = parseISO(person.value.birthDate);
      return appendYearsOld(personAgeToday(birthDate));
    };

    return {
      person,
      prettifiedBirthPlace,
      localPersonAge,
      friendlyDate,
      prettifyQualification,
      prettifyGender,
      prettifyMaritalStatus,
    };
  },
});
