import {
  ref,
  onMounted,
  defineComponent,
  computed,
  reactive,
} from "@vue/composition-api";
import { UnitApplication } from "@/controllers/unitApplications";
import {
  unitApplicationPlacementForDisplayController,
  UnitApplicationPlacementForDisplay,
} from "@/controllers/unitApplicationPlacements";

interface PossiblePlacementSlot {
  unitId: number;
  unitName: string;
  replacePlacementId: number;
  replacePlacementName: string;
  replacePlacementStartDate: string;
  replacePlacementEndDate: string;
  startDate: string;
  endDate: string;
  busyState: boolean;
}

import {
  prettifyUAStatus,
  prettifyUAPStatus,
  prettifyUnitPosition,
  friendlyDate,
} from "@/utilities/displayHelpers";

import {
  unitPositionTermLengthController,
  UnitPositionTermLength,
} from "@/controllers/unitPositionTermLengths";

import {
  MatchReplacementUnitApplicationStatus,
  matchReplacementUnitApplicationController,
} from "@/controllers/matchReplacementUnitApplications";
import { TOAST_OPTIONS } from "@/constants";

import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import addMonths from "date-fns/addMonths";
import ReplacementForm from "@/components/UnitFindReplacementDashboard/ReplacementForm.vue";

import { unitApplications } from "@/controllers/personDashboard";

import UOtherPeopleModal from "@/components/UOtherPeopleModal/UOtherPeopleModal.vue";

import { PersonUnitDataItem } from "../PersonUnitData/person-unit-data";
import { NamedRts } from "@/router";

export default defineComponent({
  name: "personDashboardUnitApplicationPlacementOptions",
  props: {
    unitApplicationId: {
      type: Number,
      required: true,
    },
  },
  components: {
    ReplacementForm,
    UOtherPeopleModal,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);
    const replacementFormMode = ref(false);
    const placement = ref<UnitApplicationPlacementForDisplay | null>(null);

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    // Person Dashboard Unit Application Placement Route
    // rerouting them through the other component so
    // the data loads correctly
    const placementRoute = (p: PossiblePlacementSlot) => {
      return {
        name: NamedRts.personUnitData,
        params: {
          itemType: PersonUnitDataItem.unitApplicationPlacement,
          itemId: p.replacePlacementId,
        },
      };
    };

    // const unitApplication = computed(() => {
    //   return $store.getters.unitApplication as UnitApplication;
    // });
    const unitApplication = computed(() => {
      return (unitApplications.value.find(item => {
        return item.id === props.unitApplicationId;
      }) as unknown) as UnitApplication;
    });

    const unmatchedPlacementIds = computed(() => {
      return $store.getters.unmatchedPlacementIds as Set<number>;
    });

    const possiblePlacements = reactive<PossiblePlacementSlot[]>([]);

    // Other People Modal
    const otherPeopleModalVisible = ref(false);
    const otherPeopleTitle = ref("");
    const otherPeopleContent = ref<any[]>([]);

    const showOtherPeopleModal = (p: PossiblePlacementSlot) => {
      otherPeopleTitle.value = p.unitName;
      const otherPeople = otherPlacementsAtUnit(
        p.unitId,
        p.startDate,
        p.endDate,
      );
      otherPeopleContent.value = otherPeople.map(op => {
        const cOverlap = congregationOverlap(unitApplication.value, op);
        return {
          name: op.name,
          congregationName: op.congregationName,
          congregationOverlap: cOverlap,
          unitApplicationPosition: op.unitApplicationPosition,
          prettifiedUnitApplicationPosition: prettifyUnitPosition(
            op.unitApplicationPosition,
          ),
          status: op.status,
          prettifiedStatus: prettifyUAPStatus(op.status),
          startDate: op.placementStartDate,
          endDate: op.placementEndDate,
          friendlyStartDate: friendlyDate(op.placementStartDate),
          friendlyEndDate: friendlyDate(op.placementEndDate),
        };
      });
      otherPeopleModalVisible.value = true;
    };

    const closeOtherPeopleModal = () => {
      otherPeopleModalVisible.value = false;
    };

    // end other people modal

    const isMatched = (placement: UnitApplicationPlacementForDisplay) => {
      if (unmatchedPlacementIds.value.has(placement.id)) {
        return false;
      } else {
        return true;
      }
    };

    const unmatchApplication = (placement: {
      id: number;
      busyState: boolean;
    }) => {
      placement.busyState = true;
      matchReplacementUnitApplicationController
        .createMatchReplacementUnitApplication({
          id: 0,
          status: MatchReplacementUnitApplicationStatus.unmatched,
          unitApplicationId: unitApplication.value.id,
          unitApplicationPlacementId: placement.id,
        })
        .then(() => {
          ctx.root.$bvToast.toast("Successfully unmatched", TOAST_OPTIONS);
          return matchReplacementUnitApplicationController.needMatchReplacementUnitApplications(
            {
              unit_application_id: unitApplication.value.id,
            },
          );
        })
        .then(() => {
          return loadPossiblePlacements();
        });
    };

    const loadPlacementForm = (p: { id: number; unitId: number }) => {
      // find the placement that they are replacing
      // there would be other ways to find this placement
      // looping over the array for the second time is not the fastest way
      // to do it.
      const p2 = unreplacedPlacementsForUnit(p.unitId).find(item => {
        return item.id === p.id;
      });

      if (p2) {
        placement.value = p2;
      }
      toggleReplacementFormMode();
    };

    const toggleReplacementFormMode = () => {
      replacementFormMode.value = !replacementFormMode.value;
    };

    const applicationPlaced = () => {
      ctx.emit("applicationPlaced");
    };

    const unreplacedPlacementsForUnit = (id: number) => {
      return $store.getters.unreplacedPlacementsForUnit(
        id,
        unitApplication.value.position,
      ) as UnitApplicationPlacementForDisplay[];
    };

    const possiblePlacementsForUnit = (id: number) => {
      return unreplacedPlacementsForUnit(id).reduce((accum, item) => {
        if (isMatched(item)) {
          accum.push(item);
          return accum;
        } else {
          return accum;
        }
      }, [] as UnitApplicationPlacementForDisplay[]);
    };

    const calculateReplacementTerm = (
      uap: UnitApplicationPlacementForDisplay,
    ) => {
      const ed = formatISO(
        addMonths(parseISO(uap.placementEndDate), termLength(uap.unitId)),
        { representation: "date" },
      );
      return {
        startDate: uap.placementEndDate,
        endDate: ed,
      };
    };

    const otherPlacementsAtUnit = (
      unitId: number,
      startDate: string,
      endDate: string,
    ) => {
      return $store.getters.placementsForUnitForDateRange(
        unitId,
        startDate,
        endDate,
      ) as UnitApplicationPlacementForDisplay[];
    };

    const termLength = (unitId: number): number => {
      const t = $store.getters.unitWithPositionTermLengthLookup(
        unitApplication.value.position,
        unitId,
      ) as UnitPositionTermLength;

      if (t) {
        return t.termLength;
      } else {
        return 0;
      }
    };

    const congregationOverlap = (
      uaOne: { congregationId: number },
      uapTwo: { congregationId: number },
    ) => {
      if (uaOne.congregationId === uapTwo.congregationId) {
        return true;
      } else {
        return false;
      }
    };

    onMounted(() => {
      // the unit application is already loaded
      // this is just getting the options for the placements
      // get a list of the ids of the units that this person has as his preference
      let preferenceUnitIds = unitApplication.value.unitApplicationPreferences.map(
        item => {
          return item.unitId;
        },
      );

      // there is the possibility that there are no preferences
      // Rails strips out an empty array, and then the be controller
      // gets all the placements and it should get none.
      if (preferenceUnitIds.length === 0) {
        preferenceUnitIds = [0];
      }

      Promise.all([
        unitPositionTermLengthController.needUnitPositionTermLengths({
          position: unitApplication.value.position,
        }),
        unitApplicationPlacementForDisplayController.needUnitApplicationPlacementsForDisplay(
          {
            unit_ids: [...preferenceUnitIds],
          },
        ),
        matchReplacementUnitApplicationController.needMatchReplacementUnitApplications(
          {
            unit_application_id: Number(unitApplication.value.id),
          },
        ),
      ])
        .then(() => {
          return loadPossiblePlacements();
        })
        .finally(() => {
          dataLoaded.value = true;
        });
    });

    const loadPossiblePlacements = () => {
      // clear out the array before starting.
      possiblePlacements.length = 0;
      // build an array of the possible placements for the unit application
      // need to take all of the preferences, then get the options for that preference
      const pp: PossiblePlacementSlot[] = unitApplication.value.unitApplicationPreferences.flatMap(
        item => {
          const uuap = possiblePlacementsForUnit(item.unitId);

          return uuap.map(innerItem => {
            const replacementTerm = calculateReplacementTerm(innerItem);
            // this data is being calculated more then once
            // here and also when the modal is shown to show other
            // people at the unit
            const otherPlacements = otherPlacementsAtUnit(
              item.unitId,
              replacementTerm.startDate,
              replacementTerm.endDate,
            );
            const cOverlap = otherPlacements.some(item => {
              return congregationOverlap(unitApplication.value, item);
            });

            return {
              id: innerItem.id,
              unitId: item.unitId,
              unitName: item.unitName,
              congregationOverlap: cOverlap,
              replacePlacementId: innerItem.id,
              replacePlacementName: innerItem.name,
              replacePlacementStartDate: innerItem.placementStartDate,
              replacePlacementEndDate: innerItem.placementEndDate,
              startDate: replacementTerm.startDate,
              endDate: replacementTerm.endDate,
              busyState: false,
            };
          });
        },
      );

      possiblePlacements.push(...pp);
      return true;
    };

    const isAvailable = (pp: PossiblePlacementSlot) => {
      return unitApplication.value.availableDate <= pp.startDate;
    };

    return {
      isAdmin,
      dataLoaded,
      unitApplication,
      possiblePlacementsForUnit,
      otherPlacementsAtUnit,
      unmatchApplication,
      toggleReplacementFormMode,
      loadPlacementForm,
      replacementFormMode,
      placement,
      applicationPlaced,

      prettifyUAStatus,
      prettifyUAPStatus,
      prettifyUnitPosition,
      friendlyDate,

      congregationOverlap,

      possiblePlacements,

      otherPeopleModalVisible,
      otherPeopleTitle,
      otherPeopleContent,
      showOtherPeopleModal,
      closeOtherPeopleModal,

      isAvailable,
      placementRoute,
    };
  },
});
