import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import { NamedRts } from "@/router";
import { SMMode } from "@/store/sidebarMenu";
import { StoreActions } from "@/store";
import { UnitApplicationStatus } from "@/controllers/unitApplications";
import { submittedUnitApplicationsController } from "@/controllers/submittedUnitApplications";

interface QueryObject {
  statuses: UnitApplicationStatus[];
}

export default defineComponent({
  name: "recruiterDashboard",

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    // sidebar menu code
    const sidebarMenuClassObject = computed(() => {
      return $store.getters.sidebarMenuClassObject;
    });

    const dataColClassObject = computed(() => {
      return $store.getters.dataColClassObject;
    });

    const showMenu = () => {
      $store.dispatch(StoreActions.setSmMode, SMMode.menu);
    };

    const showData = () => {
      $store.dispatch(StoreActions.setSmMode, SMMode.data);
    };

    const compareQueryArrays = (
      arrOne: UnitApplicationStatus[],
      arrTwo: UnitApplicationStatus[],
    ): boolean => {
      if (arrOne.length !== arrTwo.length) {
        return false;
      } else {
        for (let i = 0; i < arrOne.length; i++) {
          if (arrOne[i] !== arrTwo[i]) {
            return false;
          }
        }

        return true;
      }
    };

    const dashboardLinkClasses = (rtName: any, qry?: any) => {
      if (rtName === ctx.root.$route.name) {
        if (qry && ctx.root.$route.query.statuses) {
          const arrOne = (ctx.root.$route.query as unknown) as QueryObject;
          const arrTwo = qry as QueryObject;
          if (compareQueryArrays(arrOne.statuses, arrTwo.statuses)) {
            return {
              active: true,
            };
          } else {
            return {
              active: false,
            };
          }
        } else {
          return {
            active: true,
          };
        }
      } else {
        return {
          active: false,
        };
      }
    };

    const applicationSubLists = [
      {
        name: "Available",
        key: 0,
        route: {
          name: NamedRts.unitApplicationMainList,
          query: {
            statuses: [
              UnitApplicationStatus.available,
              UnitApplicationStatus.teaching,
            ],
          },
        },
      },
      {
        name: "Pending",
        key: 1,
        route: {
          name: NamedRts.unitApplicationMainList,
          query: {
            statuses: [UnitApplicationStatus.pending],
          },
        },
      },
      {
        name: "On Hold, Wait",
        key: 2,
        route: {
          name: NamedRts.unitApplicationMainList,
          query: {
            statuses: [
              UnitApplicationStatus.onHold,
              UnitApplicationStatus.untilVolunteerNotifies,
            ],
          },
        },
      },
      {
        name: "Placed",
        key: 3,
        route: {
          name: NamedRts.unitApplicationMainList,
          query: {
            statuses: [UnitApplicationStatus.placed],
          },
        },
      },
      {
        name: "Unavailable",
        key: 4,
        route: {
          name: NamedRts.unitApplicationMainList,
          query: {
            statuses: [UnitApplicationStatus.unavailable],
          },
        },
      },
    ];

    const routeParams = {
      submittedUnitApplicationList: {
        name: NamedRts.submittedUnitApplicationList,
      },
      recruiterInstructions: {
        name: NamedRts.recruiterInstructions,
      },
      workInProgress: {
        name: NamedRts.recruiterWorkInProgress,
      },
      personUnitApplicationForm: {
        name: NamedRts.personUnitApplicationForm,
        params: {
          unitApplicationId: "0",
        },
      },
      personForm: {
        name: NamedRts.personForm,
        params: {
          personId: "0",
        },
      },

      personList: {
        name: NamedRts.personList,
      },

      unitApplicationMainList: {
        name: NamedRts.unitApplicationMainList,
      },

      unitApplicationUnreplacedPlacements: {
        name: NamedRts.unitApplicationUnreplacedPlacements,
      },

      unitApplicationPlacements: {
        name: NamedRts.unitApplicationPlacements,
      },
    };

    onMounted(() => {
      Promise.all([
        submittedUnitApplicationsController.needSubmittedUnitApplications(),
      ]).finally(() => {
        dataLoaded.value = true;
      });
    });

    const countOfSubmittedUnitApplications = computed(() => {
      return $store.getters.countOfSubmittedUnitApplicationsForTable as number;
    });

    return {
      showMenu,
      showData,
      sidebarMenuClassObject,
      dataColClassObject,
      dashboardLinkClasses,

      isAdmin,
      countOfSubmittedUnitApplications,
      dataLoaded,
      routeParams,
      applicationSubLists,
    };
  },
});
