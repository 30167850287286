import {
  ApiCongregation,
  ApiCongregationParams,
} from "@/controllers/congregations";
import { ApiAppRoleParams } from "@/controllers/appRoles";
import {
  ApiPreparatoryClass,
  ApiPreparatoryClassParams,
} from "@/controllers/preparatoryClasses";
import {
  ApiPreparatoryClassSeason,
  ApiPreparatoryClassSeasonParams,
} from "@/controllers/preparatoryClassSeasons";
import {
  ApiSubmittedPreparatoryClassApplication,
  ApiSubmittedPreparatoryClassApplicationParams,
} from "@/controllers/submittedPreparatoryClassApplications";
import {
  ApiSubmittedUnitApplication,
  ApiSubmittedUnitApplicationParams,
} from "@/controllers/submittedUnitApplications";
import {
  ApiPreparatoryClassApplication,
  ApiPreparatoryClassApplicationParams,
  ApiPreparatoryClassApplicationIndexParams,
} from "@/controllers/preparatoryClassApplications";
import {
  ApiPreparatoryClassInstructor,
  ApiPreparatoryClassInstructorParams,
} from "@/controllers/preparatoryClassInstructors";
import {
  ApiTextDocument,
  ApiTextDocumentParams,
} from "@/controllers/textDocuments";
import {
  ApiCourtesyCommitteeMember,
  ApiCourtesyCommitteeMemberIndexParams,
  ApiCourtesyCommitteeMemberParams,
} from "@/controllers/courtesyCommitteeMembers";
import {
  ApiInstructorPreparatoryClassDetail,
  ApiInstructorPreparatoryClassDetailParams,
  ApiInstructorPreparatoryClassDetailIndexParams,
} from "@/controllers/instructorPreparatoryClassDetails";
import { ApiState, ApiStateParams } from "@/controllers/states";
import { ApiUnit, ApiUnitParams } from "@/controllers/units";
import {
  ApiUnitPositionTermLength,
  ApiUnitPositionTermLengthParams,
  ApiUnitPositionTermLengthIndexParams,
} from "@/controllers/unitPositionTermLengths";
import {
  ApiUnitApplication,
  ApiUnitApplicationParams,
  ApiUnitApplicationIndexParams,
} from "@/controllers/unitApplications";

import {
  ApiPinnedUnitApplicationFull,
  ApiPinnedUnitApplicationPartial,
  ApiPinnedUnitApplicationParams,
} from "@/controllers/pinnedUnitApplications";

import {
  ApiPinnedUnitApplicationPlacementFull,
  ApiPinnedUnitApplicationPlacementPartial,
  ApiPinnedUnitApplicationPlacementParams,
} from "@/controllers/pinnedUnitApplicationPlacements";

import {
  ApiMatchReplacementUnitApplication,
  ApiMatchReplacementUnitApplicationIndexParams,
  ApiMatchReplacementUnitApplicationParams,
} from "@/controllers/matchReplacementUnitApplications";

import {
  ApiUANote,
  ApiUnitApplicationNoteIndexParams,
  ApiUnitApplicationNoteParams,
} from "@/controllers/unitApplicationNotes";

import {
  ApiUnitApplicationPreference,
  ApiUnitApplicationPreferenceIndexParams,
} from "@/controllers/unitApplicationPreferences";

import { ApiRecentActivities } from "@/controllers/unitRecentActivity";
import { HTTP } from "./axios";
import { ApiCountry, ApiCountryParams } from "@/controllers/countries";

import { ApiSessionParams, ApiSessionResponse } from "@/controllers/sessions";
import { getSessionToken } from "@/clientStore";
import {
  ApiPreparatoryClassNote,
  ApiPreparatoryClassNoteParams,
} from "@/controllers/pcNotes";
import {
  ApiPreparatoryClassApplicationNote,
  ApiPreparatoryClassApplicationNoteParams,
} from "@/controllers/pcaNotes";
import {
  ApiPreparatoryClassTask,
  ApiPreparatoryClassTaskParams,
} from "@/controllers/pcTasks";

import {
  ApiPerson,
  ApiPersonParams,
  ApiPersonIndexParams,
  ApiSimilarPeopleParams,
} from "@/controllers/people";
import { UserSettingName } from "@/store/userSettings";
import { ApiUserSettingParams } from "@/controllers/userSettings";
import {
  ApiPreparatoryClassCommunicationParams,
  ApiPreparatoryClassApplicationCommunicationParams,
} from "@/controllers/placementPacket";
import {
  ApiUnitApplicationPlacementDisplayIndexParams,
  ApiUnitApplicationPlacementForDisplay,
  ApiUnitApplicationPlacementParams,
} from "@/controllers/unitApplicationPlacements";
import {
  ApiEmailBuilderResponse,
  ApiEmailBuilderParams,
} from "@/controllers/emailBuilders";
import {
  ApiUnitApplicationPlacementWarning,
  ApiUnitApplicationPlacementWarningIndexParams,
} from "@/controllers/unitApplicationPlacementWarnings";
import {
  ApiUnitApplicationCancelationSpotParams,
  ApiUnitApplicationCancelationSpotIndexParams,
  ApiUnitApplicationCancelationSpot,
} from "@/controllers/unitApplicationCancelationSpots";

interface ApiErrors {
  errors: string[];
}

const headersBuilder = () => {
  const token = getSessionToken();

  if (token !== "") {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  } else {
    return {
      headers: {
        Authorization: "Bearer",
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  }
};

const formDataHeadersBuilder = () => {
  const token = getSessionToken();

  if (token !== "") {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer",
      },
    };
  }
};

const blobHeadersBuilder = () => {
  const token = getSessionToken();

  if (token !== "") {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob" as "blob",
    };
  } else {
    return {
      headers: {
        Authorization: "Bearer",
      },
    };
  }
};

// Generic API Calls
// These are the generic Api calls
// The specific functions for items
// should just set the url, and the correct params
// and call the these functions
async function postFormDataItem(url: string, p: FormData) {
  return HTTP.post(url, p, formDataHeadersBuilder())
    .then((response: any) => {
      return response["data"];
    })
    .catch(e => {
      const errors = e["response"]["data"] as ApiErrors;
      return Promise.reject(errors.errors);
    });
}

async function postItem(url: string, p: any) {
  return HTTP.post(url, p, headersBuilder())
    .then((response: any) => {
      return response["data"];
    })
    .catch(e => {
      const errors = e["response"]["data"] as ApiErrors;
      return Promise.reject(errors.errors);
    });
}

async function getItems(url: string) {
  return HTTP.get(url, headersBuilder())
    .then((response: any) => {
      return response["data"];
    })
    .catch(e => {
      const errors = e["response"]["data"] as ApiErrors;
      return Promise.reject(errors.errors);
    });
}

async function getBlob(url: string) {
  return HTTP.get(url, blobHeadersBuilder())
    .then((response: any) => {
      return response["data"];
    })
    .catch(e => {
      const errors = e["response"]["data"] as ApiErrors;
      return Promise.reject(errors.errors);
    });
}

async function patchItem(url: string, p: any) {
  return HTTP.patch(url, p, headersBuilder())
    .then((response: any) => {
      return response["data"];
    })
    .catch(e => {
      const errors = e["response"]["data"] as ApiErrors;
      return Promise.reject(errors.errors);
    });
}

async function deleteItem(url: string) {
  return HTTP.delete(url, headersBuilder())
    .then((response: any) => {
      return response["data"];
    })
    .catch(e => {
      const errors = e["response"]["data"] as ApiErrors;
      return Promise.reject(errors.errors);
    });
}

// End Generic API calls

// User settings
async function postUserSetting(p: ApiUserSettingParams) {
  return postItem("/user_settings", p);
}

async function getUserSetting(internalName: UserSettingName) {
  return getItems(`/user_settings?internal_name=${internalName}`);
}
// end User settings

// App Roles
async function patchAppRole(id: number, p: ApiAppRoleParams) {
  return patchItem("/app_roles/" + id, p);
}

async function getAppRoles() {
  return getItems("/app_roles.json");
}
// end App Roles

// Countries
async function postCountry(p: ApiCountryParams) {
  return postItem("/countries", p);
}

async function getCountry(countryId: string): Promise<ApiCountry> {
  return getItems("/countries/" + countryId + ".json");
}

async function getCountries(): Promise<ApiCountry[]> {
  return getItems("/countries.json");
}

async function getPublicCountries(): Promise<ApiCountry[]> {
  return getItems("/public/countries.json");
}

async function patchCountry(id: number, p: ApiCountryParams) {
  return patchItem("/countries/" + id, p);
}

async function deleteCountry(id: number) {
  return deleteItem("/countries/" + id);
}

// End Countries

// States
async function postState(p: ApiStateParams) {
  return postItem("/states", p);
}

async function getState(stateId: string): Promise<ApiState> {
  return getItems("/states/" + stateId + ".json");
}

async function getStates(): Promise<ApiState[]> {
  return getItems("/states.json");
}

async function patchState(id: number, p: ApiStateParams) {
  return patchItem("/states/" + id, p);
}

async function deleteState(id: number) {
  return deleteItem("/states/" + id);
}
// End States

// Congregations
async function postCongregation(p: ApiCongregationParams) {
  return postItem("/congregations", p);
}

async function getCongregation(
  congregationId: string,
): Promise<ApiCongregation> {
  return getItems("/congregations/" + congregationId + ".json");
}

async function getCongregations(): Promise<ApiCongregation[]> {
  return getItems("/congregations.json");
}

async function patchCongregation(id: number, p: ApiCongregationParams) {
  return patchItem("/congregations/" + id, p);
}

async function deleteCongregation(id: number) {
  return deleteItem("/congregations/" + id);
}

// End Congregations

// Unit Position Term Length
async function postUnitPositionTermLength(p: ApiUnitPositionTermLengthParams) {
  return postItem("/unit/unit_position_term_lengths", p);
}

async function getUnitPositionTermLength(
  id: string,
): Promise<ApiUnitPositionTermLength> {
  return getItems("/unit/unit_position_term_lengths/" + id + ".json");
}

async function getPublicUnitPositionTermLengths(
  position: string,
): Promise<ApiUnitPositionTermLength[]> {
  return HTTP.get(
    `/public/unit_position_term_lengths.json?position=${position}`,
  )
    .then((response: any) => {
      const items: ApiUnitPositionTermLength[] = response[
        "data"
      ] as ApiUnitPositionTermLength[];
      return items;
    })
    .catch((e: any) => {
      return e;
    });
}

async function getUnitPositionTermLengths(
  p?: ApiUnitPositionTermLengthIndexParams,
): Promise<ApiUnitPositionTermLength[]> {
  let qryString = "";

  if (p) {
    if (p.position) {
      qryString = `${qryString}&position=${p.position}`;
    }

    if (p.unit_id) {
      qryString = `${qryString}&unit_id=${p.unit_id}`;
    }
  }

  if (p) {
    return getItems(`/unit/unit_position_term_lengths.json?${qryString}`);
  } else {
    return getItems("/unit/unit_position_term_lengths.json");
  }
}

async function patchUnitPositionTermLength(
  id: number,
  p: ApiUnitPositionTermLengthParams,
) {
  return patchItem("/unit/unit_position_term_lengths/" + id, p);
}

async function deleteUnitPositionTermLength(id: number) {
  return deleteItem("/unit/unit_position_term_lengths/" + id);
}

// End Unit Position Term Length

// Preparatory Class Reports
async function getPreparatoryClassPeopleReport(id: number) {
  return getBlob(
    `/class/preparatory_class_people_report?preparatory_class_id=${id}`,
  );
}

async function getPreparatoryClassCertificates(id: number) {
  return getBlob(
    `/class/preparatory_class_certificates?preparatory_class_id=${id}`,
  );
}
// End Preparatory Class Reports

// Text Documents
async function getTextDocuments(): Promise<ApiTextDocument[]> {
  return getItems("/class/text_documents.json");
}

async function getTextDocument(id: string): Promise<ApiTextDocument> {
  return getItems(`/class/text_documents/${id}`);
}

async function patchTextDocument(id: number, p: ApiTextDocumentParams) {
  return patchItem(`/class/text_documents/${id}`, p);
}

// End Text Documents
//
// Email Builders

async function getEmailBuilders(): Promise<ApiEmailBuilderResponse> {
  return getItems("/class/email_builders.json");
}

// End Email Builders

// Preparatory Class Instructors
async function getPreparatoryClassInstructors(): Promise<
  ApiPreparatoryClassInstructor[]
> {
  return getItems("/class/instructors.json");
}

async function getPreparatoryClassInstructor(
  id: string,
): Promise<ApiPreparatoryClassInstructor> {
  return getItems(`/class/instructors/${id}`);
}

async function postPreparatoryClassInstructor(
  p: ApiPreparatoryClassInstructorParams,
) {
  return postItem("/class/instructors", p);
}

async function patchPreparatoryClassInstructor(
  id: number,
  p: ApiPreparatoryClassInstructorParams,
) {
  return patchItem(`/class/instructors/${id}`, p);
}

async function deletePreparatoryClassInstructor(id: number) {
  return deleteItem(`/class/instructors/${id}`);
}

async function getInstructorPreparatoryClassDetails(
  p?: ApiInstructorPreparatoryClassDetailIndexParams,
): Promise<ApiInstructorPreparatoryClassDetail[]> {
  if (p) {
    return getItems(
      `/class/instructor_preparatory_class_details.json?preparatory_class_id=${p.preparatory_class_id}`,
    );
  } else {
    return getItems("/class/instructor_preparatory_class_details.json");
  }
}

async function postInstructorPreparatoryClassDetail(
  p: ApiInstructorPreparatoryClassDetailParams,
) {
  return postItem("/class/instructor_preparatory_class_details", p);
}

async function deleteInstructorPreparatoryClassDetail(id: number) {
  return deleteItem(`/class/instructor_preparatory_class_details/${id}`);
}

// End Preparatory Class Instructors

// Preparatory Class CCM
async function postCourtesyCommitteeMember(
  p: ApiCourtesyCommitteeMemberParams,
) {
  return postItem("/class/courtesy_committee_members", p);
}

async function getCourtesyCommitteeMembers(
  p?: ApiCourtesyCommitteeMemberIndexParams,
): Promise<ApiCourtesyCommitteeMember[]> {
  if (p) {
    return getItems(
      `/class/courtesy_committee_members.json?preparatory_class_id=${p.preparatory_class_id}`,
    );
  } else {
    return getItems("/class/courtesy_committee_members.json");
  }
}

async function deleteCourtesyCommitteeMember(id: number) {
  return deleteItem("/class/courtesy_committee_members/" + id);
}

// End Preparatory Class CCM

// Preparatory Class Seasons
async function postPreparatoryClassSeason(p: ApiPreparatoryClassSeasonParams) {
  return postItem("/class/preparatory_class_seasons", p);
}

async function getPreparatoryClassSeason(
  preparatoryClassSeasonId: string,
): Promise<ApiPreparatoryClassSeason> {
  return getItems(
    "/class/preparatory_class_seasons/" + preparatoryClassSeasonId + ".json",
  );
}

async function getPreparatoryClassSeasons(): Promise<
  ApiPreparatoryClassSeason[]
> {
  return getItems("/class/preparatory_class_seasons.json");
}

async function patchPreparatoryClassSeason(
  id: number,
  p: ApiPreparatoryClassSeasonParams,
) {
  return patchItem("/class/preparatory_class_seasons/" + id, p);
}

async function deletePreparatoryClassSeason(id: number) {
  return deleteItem("/class/preparatory_class_seasons/" + id);
}

// End Preparatory Class Seasons

// PreparatoryClasses
async function postPreparatoryClass(p: ApiPreparatoryClassParams) {
  return postItem("/class/preparatory_classes", p);
}

async function getPreparatoryClass(
  preparatoryClassId: string,
): Promise<ApiPreparatoryClass> {
  return getItems("/class/preparatory_classes/" + preparatoryClassId + ".json");
}

async function getPreparatoryClasses(): Promise<ApiPreparatoryClass[]> {
  return getItems("/class/preparatory_classes.json");
}

async function patchPreparatoryClass(id: number, p: ApiPreparatoryClassParams) {
  return patchItem("/class/preparatory_classes/" + id, p);
}

async function deletePreparatoryClass(id: number) {
  return deleteItem("/class/preparatory_classes/" + id);
}
// End PreparatoryClasses

// Names
async function postPerson(p: ApiPersonParams) {
  return postItem("/people", p);
}

async function getPeople(p: ApiPersonIndexParams): Promise<ApiPerson[]> {
  let qryString = "";

  if (p) {
    if (p.updated_after) {
      qryString = `${qryString}&updated_after=${p.updated_after}`;
    }

    if (p.gender) {
      qryString = `${qryString}&gender=${p.gender}`;
    }
  }

  return getItems(`/people.json?${qryString}`);
}

async function patchPerson(id: number, p: ApiPersonParams) {
  return patchItem("/people/" + id, p);
}

async function deletePerson(id: number) {
  return deleteItem("/people/" + id);
}

async function getFirstNames() {
  return getItems("/people_names_list.json?names=first");
}

async function getMiddleNames() {
  return getItems("/people_names_list.json?names=middle");
}

async function getLastNames() {
  return getItems("/people_names_list.json?names=last");
}

async function getMales() {
  return getItems("/people_list.json?gender=male");
}

async function getFemales() {
  return getItems("/people_list.json?gender=female");
}

async function getZipForCity(city: string, stateId: string) {
  if (stateId === "0") {
    return getItems(`/people/zip_for_city.json?city=${city}`);
  } else {
    return getItems(
      `/people/zip_for_city.json?city=${city}&state_id=${stateId}`,
    );
  }
}

async function getCongregationsMinisters(congregationId: string) {
  return getItems(`/ministers_list.json?congregation_id=${congregationId}`);
}
// End Names

// People

async function getSimilarPeople(params: ApiSimilarPeopleParams) {
  return getItems(
    `/people/similar_people?first_name=${params.first_name}&birth_date=${params.birth_date}&gender=${params.gender}`,
  );
}

async function getPerson(personId: string): Promise<ApiPerson> {
  return getItems("/people/" + personId + ".json");
}
// End People

// Statistics
async function getPreparatoryClassStatistics() {
  return getItems("/class/preparatory_class_statistics.json");
}
// End Statistics

// Submitted Unit Applications
async function postSubmittedUnitApplication(
  p: ApiSubmittedUnitApplicationParams,
) {
  return postItem("/unit/submitted_unit_applications", p);
}

async function getSubmittedUnitApplication(
  submittedUnitApplicationId: string,
): Promise<ApiSubmittedUnitApplication> {
  return getItems(
    "/unit/submitted_unit_applications/" + submittedUnitApplicationId + ".json",
  );
}

async function getSubmittedUnitApplications(): Promise<
  ApiSubmittedUnitApplication[]
> {
  return getItems("/unit/submitted_unit_applications.json");
}

async function patchSubmittedUnitApplication(
  id: number,
  p: ApiSubmittedUnitApplicationParams,
) {
  return patchItem("/unit/submitted_unit_applications/" + id, p);
}

async function deleteSubmittedUnitApplication(id: number) {
  return deleteItem("/unit/submitted_unit_applications/" + id);
}
// End Submitted unit Applications

// Submitted Preparatory Class Applications
async function postSubmittedPreparatoryClassApplication(
  p: ApiSubmittedPreparatoryClassApplicationParams,
) {
  return postItem("/class/submitted_preparatory_class_applications", p);
}

async function getSubmittedPreparatoryClassApplication(
  submittedPreparatoryClassApplicationId: string,
): Promise<ApiSubmittedPreparatoryClassApplication> {
  return getItems(
    "/class/submitted_preparatory_class_applications/" +
      submittedPreparatoryClassApplicationId +
      ".json",
  );
}

async function getSubmittedPreparatoryClassApplications(): Promise<
  ApiSubmittedPreparatoryClassApplication[]
> {
  return getItems("/class/submitted_preparatory_class_applications.json");
}

async function patchSubmittedPreparatoryClassApplication(
  id: number,
  p: ApiSubmittedPreparatoryClassApplicationParams,
) {
  return patchItem("/class/submitted_preparatory_class_applications/" + id, p);
}

async function deleteSubmittedPreparatoryClassApplication(id: number) {
  return deleteItem("/class/submitted_preparatory_class_applications/" + id);
}
// End Submitted Preparatory Class Applications

// Preparatory Class Applications
async function postPreparatoryClassApplication(
  p: ApiPreparatoryClassApplicationParams,
) {
  return postItem("/class/preparatory_class_applications", p);
}

async function getPreparatoryClassApplication(
  preparatoryClassApplicationId: string,
): Promise<ApiPreparatoryClassApplication> {
  return getItems(
    "/class/preparatory_class_applications/" +
      preparatoryClassApplicationId +
      ".json",
  );
}

async function getPreparatoryClassApplications(
  p?: ApiPreparatoryClassApplicationIndexParams,
): Promise<ApiPreparatoryClassApplication[]> {
  if (p) {
    return getItems(
      `/class/preparatory_class_applications.json?preparatory_class_id=${p.preparatory_class_id}`,
    );
  } else {
    return getItems("/class/preparatory_class_applications.json");
  }
}

async function patchPreparatoryClassApplication(
  id: number,
  p: ApiPreparatoryClassApplicationParams,
) {
  return patchItem("/class/preparatory_class_applications/" + id, p);
}

async function deletePreparatoryClassApplication(id: number) {
  return deleteItem("/class/preparatory_class_applications/" + id);
}
// End Preparatory Class Applications

// Preparatory Class Notes
async function postPreparatoryClassNote(p: ApiPreparatoryClassNoteParams) {
  return postItem("/class/preparatory_class_notes", p);
}

async function getPreparatoryClassNotes(
  p: number,
): Promise<ApiPreparatoryClassNote[]> {
  return getItems(
    `/class/preparatory_class_notes.json?preparatory_class_id=${p}`,
  );
}

async function deletePreparatoryClassNote(id: number) {
  return deleteItem("/class/preparatory_class_notes/" + id);
}

// End Preparatory Class Notes

// Preparatory Class Tasks
async function postPreparatoryClassTask(p: ApiPreparatoryClassTaskParams) {
  return postItem("/class/preparatory_class_tasks", p);
}

async function patchPreparatoryClassTask(
  id: number,
  p: ApiPreparatoryClassTaskParams,
) {
  return patchItem("/class/preparatory_class_tasks/" + id, p);
}

async function getPreparatoryClassTasks(
  p: number,
): Promise<ApiPreparatoryClassTask[]> {
  return getItems(
    `/class/preparatory_class_tasks.json?preparatory_class_id=${p}`,
  );
}

async function deletePreparatoryClassTask(id: number) {
  return deleteItem("/class/preparatory_class_tasks/" + id);
}

// End Preparatory Class Tasks

// Preparatory Class Application Notes
async function postPreparatoryClassApplicationNote(
  p: ApiPreparatoryClassApplicationNoteParams,
) {
  return postItem("/class/preparatory_class_application_notes", p);
}

async function getPreparatoryClassApplicationNotes(
  p: number,
): Promise<ApiPreparatoryClassApplicationNote[]> {
  return getItems(
    `/class/preparatory_class_application_notes.json?preparatory_class_application_id=${p}`,
  );
}

async function deletePreparatoryClassApplicationNote(id: number) {
  return deleteItem("/class/preparatory_class_application_notes/" + id);
}
// End Preparatory Class Notes

async function sendEmailBuilder(p: FormData) {
  return postFormDataItem("class/email_builders", p);
}

async function sendPlacementPacket(
  p:
    | ApiPreparatoryClassCommunicationParams
    | ApiPreparatoryClassApplicationCommunicationParams,
) {
  return postItem("class/send_placement_packet", p);
}

async function sendMinisterReminder(
  p:
    | ApiPreparatoryClassCommunicationParams
    | ApiPreparatoryClassApplicationCommunicationParams,
) {
  return postItem("class/send_minister_reminder", p);
}

async function sendMinisterUpdate(
  p:
    | ApiPreparatoryClassCommunicationParams
    | ApiPreparatoryClassApplicationCommunicationParams,
) {
  return postItem("class/send_minister_update", p);
}

async function sendPlacementConfirmationReminder(
  p:
    | ApiPreparatoryClassCommunicationParams
    | ApiPreparatoryClassApplicationCommunicationParams,
) {
  return postItem("class/send_placement_confirmation_reminder", p);
}

async function sendInstructorUpdate(p: ApiPreparatoryClassCommunicationParams) {
  return postItem("class/instructor_communication_update_letter", p);
}

async function sendProtocolLetter(p: ApiPreparatoryClassCommunicationParams) {
  return postItem("class/courtesy_committee_communication_class_protocol", p);
}

async function sendCommitteeProtocolLetter(
  p: ApiPreparatoryClassCommunicationParams,
) {
  return postItem(
    "class/courtesy_committee_communication_committee_protocol",
    p,
  );
}

async function sendCommitteeUpdate(p: ApiPreparatoryClassCommunicationParams) {
  return postItem("class/courtesy_committee_communication_update_letter", p);
}

async function getPublicPreparatoryClasses(
  classification: string,
): Promise<ApiPreparatoryClass[]> {
  return HTTP.get(
    `/public/preparatory_classes.json?classification=${classification}`,
  )
    .then((response: any) => {
      const preparatoryClasses: ApiPreparatoryClass[] = response[
        "data"
      ] as ApiPreparatoryClass[];
      return preparatoryClasses;
    })
    .catch((e: any) => {
      return Promise.reject(e);
    });
}

async function getPublicUnits(classification: string): Promise<ApiUnit[]> {
  return HTTP.get(`/public/units.json?classification=${classification}`)
    .then((response: any) => {
      const units: ApiUnit[] = response["data"] as ApiUnit[];
      return units;
    })
    .catch((e: any) => {
      return e;
    });
}

// Units
async function postUnit(p: ApiUnitParams) {
  return postItem("/unit/units", p);
}

async function getUnit(unitId: string): Promise<ApiUnit> {
  return getItems("/unit/units/" + unitId + ".json");
}

async function getUnitsList(classification: string): Promise<ApiUnit[]> {
  return HTTP.get(`/units_list.json?classification=${classification}`)
    .then((response: any) => {
      const units: ApiUnit[] = response["data"] as ApiUnit[];
      return units;
    })
    .catch((e: any) => {
      return e;
    });
}

async function getUnits(status: string): Promise<ApiUnit[]> {
  return getItems(`/unit/units.json?status=${status}`);
}

async function patchUnit(id: number, p: ApiUnitParams) {
  return patchItem("/unit/units/" + id, p);
}

async function deleteUnit(id: number) {
  return deleteItem("/unit/units/" + id);
}
// End Units

async function postPublicSubmittedPreparatoryClassApplication(
  params: any,
): Promise<string[]> {
  return HTTP.post(
    "/public/submitted_preparatory_class_applications.json",
    params,
  )
    .then((_response: any) => {
      return ["success"];
    })
    .catch((e: any) => {
      const errors = e["response"]["data"] as ApiErrors;
      return Promise.reject(errors.errors);
    });
}

async function postPublicSubmittedUnitApplication(
  params: any,
): Promise<string[]> {
  return HTTP.post("/public/submitted_unit_applications.json", params)
    .then((_response: any) => {
      return ["success"];
    })
    .catch((e: any) => {
      const errors = e["response"]["data"] as ApiErrors;
      return Promise.reject(errors.errors);
    });
}
// UnitApplicationPlacementWarnings
async function getUnitApplicationPlacementWarnings(
  p?: ApiUnitApplicationPlacementWarningIndexParams,
): Promise<ApiUnitApplicationPlacementWarning[]> {
  let qryString = "";

  if (p) {
    qryString = `${qryString}&unit_id=${p.unit_id}`;
    return getItems(
      `/unit/unit_application_placement_warnings.json?${qryString}`,
    );
  } else {
    return getItems("/unit/unit_application_placement_warnings.json");
  }
}

// Pinned Unit Applications
async function postPinnedUnitApplication(p: ApiPinnedUnitApplicationParams) {
  return postItem("/unit/pinned_unit_applications", p);
}

async function getPinnedUnitApplication(
  unitApplicationId: string,
): Promise<ApiPinnedUnitApplicationPartial> {
  return getItems(
    "/unit/pinned_unit_applications/" + unitApplicationId + ".json",
  );
}

async function getPinnedUnitApplications(): Promise<
  ApiPinnedUnitApplicationFull[]
> {
  return getItems("/unit/pinned_unit_applications.json");
}

async function deletePinnedUnitApplication(id: number) {
  return deleteItem("/unit/pinned_unit_applications/" + id);
}
// End Pinned Unit Applications

// Pinned Unit Application Placements
async function postPinnedUnitApplicationPlacement(
  p: ApiPinnedUnitApplicationPlacementParams,
) {
  return postItem("/unit/pinned_unit_application_placements", p);
}

async function getPinnedUnitApplicationPlacement(
  unitApplicationPlacementId: string,
): Promise<ApiPinnedUnitApplicationPlacementPartial> {
  return getItems(
    "/unit/pinned_unit_application_placements/" +
      unitApplicationPlacementId +
      ".json",
  );
}

async function getPinnedUnitApplicationPlacements(): Promise<
  ApiPinnedUnitApplicationPlacementFull[]
> {
  return getItems("/unit/pinned_unit_application_placements.json");
}

async function deletePinnedUnitApplicationPlacement(id: number) {
  return deleteItem("/unit/pinned_unit_application_placements/" + id);
}
// End Pinned Unit Applications

// Match Replacement Unit Applications
async function postMatchReplacementUnitApplication(
  p: ApiMatchReplacementUnitApplicationParams,
) {
  return postItem("/unit/match_replacement_unit_applications", p);
}

async function getMatchReplacementUnitApplications(
  p: ApiMatchReplacementUnitApplicationIndexParams,
): Promise<ApiMatchReplacementUnitApplication[]> {
  let qryString = "";

  if (p.unit_application_id) {
    qryString = `${qryString}&unit_application_id=${p.unit_application_id}`;
  }

  if (p.unit_application_placement_id) {
    qryString = `${qryString}&unit_application_placement_id=${p.unit_application_placement_id}`;
  }

  return getItems(
    `/unit/match_replacement_unit_applications.json?${qryString}`,
  );
}

async function getMatchReplacementUnitApplication(
  id: string,
): Promise<ApiMatchReplacementUnitApplication> {
  return getItems("/unit/match_replacement_unit_applications/" + id + ".json");
}

async function deleteMatchReplacementUnitApplication(id: number) {
  return deleteItem("/unit/match_replacement_unit_applications/" + id);
}
// End Match Replacement Unit Applications
//
// Unit Application cancelation spot
async function postUnitApplicationCancelationSpot(
  p: ApiUnitApplicationCancelationSpotParams,
) {
  return postItem("/unit/unit_application_cancelation_spots", p);
}

async function getUnitApplicationCancelationSpots(
  p?: ApiUnitApplicationCancelationSpotIndexParams,
): Promise<ApiUnitApplicationCancelationSpot[]> {
  let qryString = "";

  if (p) {
    if (p.unit_id) {
      qryString = `${qryString}&unit_id=${p.unit_id}`;
    }

    if (p.after_date) {
      qryString = `${qryString}&after_date=${p.after_date}`;
    }

    if (p.unit_application_id) {
      qryString = `${qryString}&unit_application_id=${p.unit_application_id}`;
    }
  }

  if (p) {
    return getItems(
      `/unit/unit_application_cancelation_spots.json?${qryString}`,
    );
  } else {
    return getItems("/unit/unit_application_cancelation_spots.json");
  }
}

async function deleteUnitApplicationCancelationSpot(id: number) {
  return deleteItem("/unit/unit_application_cancelation_spots/" + id);
}
// end Unit Application cancelation spot

// Unit Applications
async function postUnitApplication(p: ApiUnitApplicationParams) {
  return postItem("/unit/unit_applications", p);
}

async function getUnitApplication(
  unitApplicationId: string,
): Promise<ApiUnitApplication> {
  return getItems("/unit/unit_applications/" + unitApplicationId + ".json");
}

async function getUnitApplications(
  p?: ApiUnitApplicationIndexParams,
): Promise<ApiUnitApplication[]> {
  let qryString = "";

  if (p) {
    if (p.position) {
      qryString = `${qryString}&position=${p.position}`;
    }

    if (p.unit_id) {
      qryString = `${qryString}&unit_id=${p.unit_id}`;
    }

    if (p.person_id) {
      qryString = `${qryString}&person_id=${p.person_id}`;
    }

    if (p.statuses) {
      for (const s of p.statuses) {
        qryString = `${qryString}&statuses[]=${s}`;
      }
    }
  }

  if (p) {
    return getItems(`/unit/unit_applications.json?${qryString}`);
  } else {
    return getItems("/unit/unit_applications.json");
  }
}

async function patchUnitApplication(id: number, p: ApiUnitApplicationParams) {
  return patchItem("/unit/unit_applications/" + id, p);
}

async function deleteUnitApplication(id: number) {
  return deleteItem("/unit/unit_applications/" + id);
}
// End Unit Applications

// Start Unit Application Preferences
async function getUnitApplicationPreferences(
  p: ApiUnitApplicationPreferenceIndexParams,
): Promise<ApiUnitApplicationPreference[]> {
  const qryString = `&unit_application_id=${p.unit_application_id}`;
  return getItems(`/unit/unit_application_preferences.json?${qryString}`);
}
// End Unit Application Preferences

// Start Unit Recent Activity
async function getUnitRecentActivities(): Promise<ApiRecentActivities> {
  return getItems("/unit/unit_recent_activities.json");
}
// End Unit Recent Activity

// Start Unit Application Notes
async function getUnitApplicationNotes(
  p: ApiUnitApplicationNoteIndexParams,
): Promise<ApiUANote[]> {
  const qryString = `&unit_application_id=${p.unit_application_id}`;
  return getItems(`/unit/unit_application_notes.json?${qryString}`);
}

async function postUnitApplicationNote(p: ApiUnitApplicationNoteParams) {
  return postItem("/unit/unit_application_notes", p);
}

async function deleteUnitApplicationNote(id: number) {
  return deleteItem("/unit/unit_application_notes/" + id);
}

// End Unit Application Notes

// Unit Application Placements
async function getUnitApplicationPlacementsForDisplay(
  p?: ApiUnitApplicationPlacementDisplayIndexParams,
): Promise<ApiUnitApplicationPlacementForDisplay[]> {
  let qryString = "";

  if (p) {
    if (p.unit_application_position) {
      qryString = `${qryString}&unit_application_position=${p.unit_application_position}`;
    }

    if (p.unit_ids) {
      for (const u of p.unit_ids) {
        qryString = `${qryString}&unit_ids[]=${u}`;
      }
    } else if (p.unit_id) {
      qryString = `${qryString}&unit_id=${p.unit_id}`;
    }

    if (p.unit_application_id) {
      qryString = `${qryString}&unit_application_id=${p.unit_application_id}`;
    }

    if (p.unit_application_placement_ids) {
      for (const u of p.unit_application_placement_ids) {
        qryString = `${qryString}&unit_application_placement_ids[]=${u}`;
      }
    } else if (p.unit_application_replaces_ids) {
      for (const u of p.unit_application_replaces_ids) {
        qryString = `${qryString}&unit_application_replaces_ids[]=${u}`;
      }
    } else if (p.unit_application_ids) {
      for (const u of p.unit_application_ids) {
        qryString = `${qryString}&unit_application_ids[]=${u}`;
      }
    }

    return getItems(`/unit/unit_application_placements.json?${qryString}`);
  } else {
    return getItems(`/unit/unit_application_placements.json`);
  }
}

async function getUnitApplicationPlacementForDisplay(
  id: string,
): Promise<ApiUnitApplicationPlacementForDisplay> {
  return getItems(`/unit/unit_application_placements/${id}.json`);
}

async function postUnitApplicationPlacement(
  p: ApiUnitApplicationPlacementParams,
) {
  return postItem("/unit/unit_application_placements", p);
}

async function patchUnitApplicationPlacement(
  id: number,
  p: ApiUnitApplicationPlacementParams,
) {
  return patchItem(`/unit/unit_application_placements/${id}`, p);
}

async function deleteUnitApplicationPlacement(id: number) {
  return deleteItem("/unit/unit_application_placements/" + id);
}
// End Unit Application Placements

async function postSession(
  params: ApiSessionParams,
): Promise<ApiSessionResponse> {
  return HTTP.post("sessions", params)
    .then((response: any) => {
      const rData = response["data"] as ApiSessionResponse;
      return rData;
    })
    .catch((e: any) => {
      const errors = e["response"]["data"] as ApiErrors;
      return Promise.reject(errors.errors);
    });
}

async function patchSession(params: any): Promise<ApiSessionResponse> {
  return HTTP.patch("sessions", params, headersBuilder())
    .then((response: any) => {
      const rData = response["data"] as ApiSessionResponse;
      return rData;
    })
    .catch((e: any) => {
      const errors = e["response"]["data"] as ApiErrors;
      return Promise.reject(errors.errors);
    });
}

export default {
  patchSession,
  postSession,

  postCountry,
  getCountry,
  getCountries,
  getPublicCountries,
  patchCountry,
  deleteCountry,

  postState,
  getState,
  getStates,
  patchState,
  deleteState,

  postCongregation,
  getCongregation,
  getCongregations,
  patchCongregation,
  deleteCongregation,

  postUnit,
  getUnit,
  getUnits,
  getUnitsList,
  patchUnit,
  deleteUnit,

  postPerson,
  getPerson,
  getPeople,
  patchPerson,
  deletePerson,
  getSimilarPeople,

  postPreparatoryClass,
  getPreparatoryClass,
  getPreparatoryClasses,
  patchPreparatoryClass,
  deletePreparatoryClass,

  postPreparatoryClassSeason,
  getPreparatoryClassSeason,
  getPreparatoryClassSeasons,
  patchPreparatoryClassSeason,
  deletePreparatoryClassSeason,

  postSubmittedPreparatoryClassApplication,
  getSubmittedPreparatoryClassApplication,
  getSubmittedPreparatoryClassApplications,
  patchSubmittedPreparatoryClassApplication,
  deleteSubmittedPreparatoryClassApplication,

  postPreparatoryClassApplication,
  getPreparatoryClassApplication,
  getPreparatoryClassApplications,
  patchPreparatoryClassApplication,
  deletePreparatoryClassApplication,

  getPreparatoryClassInstructors,
  getPreparatoryClassInstructor,
  postPreparatoryClassInstructor,
  patchPreparatoryClassInstructor,
  deletePreparatoryClassInstructor,

  getInstructorPreparatoryClassDetails,
  postInstructorPreparatoryClassDetail,
  deleteInstructorPreparatoryClassDetail,

  getTextDocuments,
  getTextDocument,
  patchTextDocument,

  getEmailBuilders,
  sendEmailBuilder,

  getCourtesyCommitteeMembers,
  postCourtesyCommitteeMember,
  deleteCourtesyCommitteeMember,

  getPreparatoryClassStatistics,
  getFirstNames,
  getMiddleNames,
  getLastNames,
  getMales,
  getFemales,
  getZipForCity,
  getCongregationsMinisters,
  getPublicUnits,
  getPublicPreparatoryClasses,
  postPublicSubmittedPreparatoryClassApplication,

  postSubmittedUnitApplication,
  getSubmittedUnitApplication,
  getSubmittedUnitApplications,
  patchSubmittedUnitApplication,
  deleteSubmittedUnitApplication,

  postUnitApplication,
  getUnitApplication,
  getUnitApplications,
  patchUnitApplication,
  deleteUnitApplication,

  postUnitApplicationCancelationSpot,
  getUnitApplicationCancelationSpots,
  deleteUnitApplicationCancelationSpot,

  getUnitApplicationPlacementWarnings,

  postPinnedUnitApplication,
  getPinnedUnitApplication,
  getPinnedUnitApplications,
  deletePinnedUnitApplication,

  postPinnedUnitApplicationPlacement,
  getPinnedUnitApplicationPlacement,
  getPinnedUnitApplicationPlacements,
  deletePinnedUnitApplicationPlacement,

  postMatchReplacementUnitApplication,
  getMatchReplacementUnitApplication,
  getMatchReplacementUnitApplications,
  deleteMatchReplacementUnitApplication,

  getUnitApplicationNotes,
  postUnitApplicationNote,
  deleteUnitApplicationNote,

  getUnitApplicationPreferences,

  getUnitRecentActivities,

  postPublicSubmittedUnitApplication,

  getUnitApplicationPlacementsForDisplay,
  getUnitApplicationPlacementForDisplay,
  postUnitApplicationPlacement,
  patchUnitApplicationPlacement,
  deleteUnitApplicationPlacement,

  postUserSetting,
  getUserSetting,

  patchAppRole,
  getAppRoles,

  getPreparatoryClassNotes,
  deletePreparatoryClassNote,
  postPreparatoryClassNote,

  getPreparatoryClassApplicationNotes,
  deletePreparatoryClassApplicationNote,
  postPreparatoryClassApplicationNote,

  getPreparatoryClassTasks,
  deletePreparatoryClassTask,
  postPreparatoryClassTask,
  patchPreparatoryClassTask,

  getPreparatoryClassPeopleReport,
  getPreparatoryClassCertificates,

  sendPlacementPacket,
  sendMinisterReminder,
  sendMinisterUpdate,
  sendPlacementConfirmationReminder,
  sendInstructorUpdate,
  sendProtocolLetter,
  sendCommitteeProtocolLetter,
  sendCommitteeUpdate,

  postUnitPositionTermLength,
  getUnitPositionTermLength,
  getUnitPositionTermLengths,
  getPublicUnitPositionTermLengths,
  patchUnitPositionTermLength,
  deleteUnitPositionTermLength,
};
