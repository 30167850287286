import api from "@/api";
import store, { StoreActions } from "@/store";
import {
  ApiUnitApplicationNote,
  UnitApplicationNote,
  apiUnitApplicationNoteToUnitApplicationNote,
} from "./unitApplications";

export interface ApiUnitApplicationCancelationSpotIndexParams {
  after_date?: string;
  unit_id?: number;
  unit_application_id?: number;
}

export interface ApiUnitApplicationCancelationSpot {
  full_name: string;
  id: number;
  unit_application_id: number;
  unit_id: number;
  person_id: number;
  placement_status: string;
  placement_start_date: null | string;
  placement_end_date: null | string;
  placement_unit_id: null | number;
  placement_unit_name: null | string;
  placement_id: null | number;
  unit_application_notes: ApiUnitApplicationNote[];
}

export interface ApiUnitApplicationCancelationSpotParams {
  unit_application_cancelation_spot: {
    unit_id: number;
    unit_application_id: number;
  };
}

export interface UnitApplicationCancelationSpot {
  fullName: string;
  id: number;
  personId: number;
  unitApplicationId: number;
  unitId: number;
  placementStatus: null | string;
  placementStartDate: null | string;
  placementEndDate: null | string;
  placementUnitId: null | number;
  placementUnitName: null | string;
  placementId: null | number;
  unitApplicationNotes: UnitApplicationNote[];
}

export const emptyUnitApplicationCancelationSpot = (): UnitApplicationCancelationSpot => {
  return {
    fullName: "",
    id: 0,
    personId: 0,
    placementEndDate: null,
    placementId: null,
    placementStartDate: null,
    placementStatus: null,
    placementUnitId: null,
    placementUnitName: null,
    unitApplicationId: 0,
    unitApplicationNotes: [],
    unitId: 0,
  };
};

function apiUnitApplicationCancelationSpotToUnitApplicationCancelationSpot(
  p: ApiUnitApplicationCancelationSpot,
): UnitApplicationCancelationSpot {
  return {
    fullName: p.full_name,
    id: p.id,
    personId: p.person_id,
    placementEndDate: p.placement_end_date,
    placementId: p.placement_id,
    placementStartDate: p.placement_start_date,
    placementStatus: p.placement_status,
    placementUnitId: p.placement_unit_id,
    placementUnitName: p.placement_unit_name,
    unitApplicationId: p.unit_application_id,
    unitId: p.unit_id,
    unitApplicationNotes: p.unit_application_notes.map(item => {
      return apiUnitApplicationNoteToUnitApplicationNote(item);
    }),
  };
}

async function createUnitApplicationCancelationSpot(
  c: UnitApplicationCancelationSpot,
) {
  const cParams: ApiUnitApplicationCancelationSpotParams = {
    unit_application_cancelation_spot: {
      unit_id: c.unitId,
      unit_application_id: c.unitApplicationId,
    },
  };

  return api
    .postUnitApplicationCancelationSpot(cParams)
    .then((response: ApiUnitApplicationCancelationSpot) => {
      return store.dispatch(
        StoreActions.loadUnitApplicationCancelationSpot,
        apiUnitApplicationCancelationSpotToUnitApplicationCancelationSpot(
          response,
        ),
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function needUnitApplicationCancelationSpots(
  p?: ApiUnitApplicationCancelationSpotIndexParams,
) {
  return api
    .getUnitApplicationCancelationSpots(p)
    .then((response: ApiUnitApplicationCancelationSpot[]) => {
      const c = response.map(item => {
        return apiUnitApplicationCancelationSpotToUnitApplicationCancelationSpot(
          item,
        );
      });
      return store.dispatch(
        StoreActions.loadUnitApplicationCancelationSpots,
        c,
      );
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

async function deleteUnitApplicationCancelationSpot(
  c: UnitApplicationCancelationSpot,
) {
  return api
    .deleteUnitApplicationCancelationSpot(c.id)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const unitApplicationCancelationSpotController = {
  createUnitApplicationCancelationSpot,
  needUnitApplicationCancelationSpots,
  deleteUnitApplicationCancelationSpot,
};
