import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api";
import LoadingIndicator from "@/components/Loading/Loading.vue";
import { UnitApplicationPlacementForDisplay } from "@/controllers/unitApplicationPlacements";
import {
  unitApplicationCancelationSpotController,
  UnitApplicationCancelationSpot,
} from "@/controllers/unitApplicationCancelationSpots";
import { prettifyUAPStatus, friendlyDate } from "@/utilities/displayHelpers";
import UapStatus from "@/components/UAPStatus/UAPStatus.vue";
import { PersonUnitDataItem } from "../PersonUnitData/person-unit-data";
import { NamedRts } from "@/router";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parseISO from "date-fns/parseISO";

export default defineComponent({
  name: "unitApplicationsList",
  components: {
    LoadingIndicator,
    UapStatus,
  },

  setup(props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    // Person Dashboard Unit Application Placement Route
    // rerouting them through the other component so
    // the data loads correctly
    const personRoute = (cancelationSpot: UnitApplicationCancelationSpot) => {
      return {
        name: NamedRts.personUnitData,
        params: {
          itemType: PersonUnitDataItem.person,
          itemId: cancelationSpot.personId,
        },
      };
    };

    const placement = computed(() => {
      return $store.getters
        .unitApplicationPlacementForDisplay as UnitApplicationPlacementForDisplay;
    });

    const cancelationSpots = computed(() => {
      return $store.getters
        .unitApplicationCancelationSpots as UnitApplicationCancelationSpot[];
    });

    onMounted(() => {
      loadData();
    });

    const loadData = () => {
      unitApplicationCancelationSpotController
        .needUnitApplicationCancelationSpots({
          unit_id: placement.value.unitId,
          after_date: placement.value.placementEndDate,
        })
        .then(() => {
          dataLoaded.value = true;
        });
    };

    return {
      dataLoaded,
      placement,
      cancelationSpots,

      friendlyDate,
      formatDistanceToNow,
      parseISO,

      prettifyUAPStatus,
      personRoute,
    };
  },
});
