import { defineComponent } from "@vue/composition-api";
import UapStatus from "@/components/UAPStatus/UAPStatus.vue";

import { UnitApplicationPlacementForDisplay } from "@/controllers/unitApplicationPlacements";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parseISO from "date-fns/parseISO";
import startOfToday from "date-fns/startOfToday";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { NamedRts } from "@/router";
import { PersonUnitDataItem } from "../PersonUnitData/person-unit-data";

import {
  prettifyUAPStatus,
  prettifyUnitPosition,
  friendlyDate,
  hBadgeForUnitPosition,
  hBadgeForUAPStatus,
} from "@/utilities/displayHelpers";

export default defineComponent({
  name: "unitApplicationPlacementShowPartial",
  components: {
    UapStatus,
  },
  props: {
    p: {
      type: Object as () => UnitApplicationPlacementForDisplay,
      required: true,
    },
    checkReplacement: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const hBgForLeavesIn = (pl: UnitApplicationPlacementForDisplay) => {
      if (props.checkReplacement) {
        const td = startOfToday();
        const ed = parseISO(pl.placementEndDate);
        const difference = differenceInCalendarDays(ed, td);

        if (pl.replacement) {
          return "bg-success";
        } else if (difference < 31) {
          return "bg-danger";
        } else if (difference < 61) {
          return "bg-warning";
        } else {
          return "bg-success";
        }
      } else {
        return "bg-secondary";
      }
    };

    const leavesOrLeft = (pl: UnitApplicationPlacementForDisplay) => {
      const td = startOfToday();
      const endDate = parseISO(pl.placementEndDate);

      if (td > endDate) {
        // person has already left
        return `Left ${formatDistanceToNow(parseISO(pl.placementEndDate))} ago`;
      } else {
        return `Leaves in ${formatDistanceToNow(
          parseISO(pl.placementEndDate),
        )}`;
      }
    };

    // Person Dashboard Unit Application Placement Route
    // rerouting them through the other component so
    // the data loads correctly
    const pdUapRoute = (placement: UnitApplicationPlacementForDisplay) => {
      return {
        name: NamedRts.personUnitData,
        params: {
          itemType: PersonUnitDataItem.unitApplicationPlacement,
          itemId: placement.id,
        },
      };
    };

    return {
      hBgForLeavesIn,
      formatDistanceToNow,
      parseISO,

      prettifyUnitPosition,
      prettifyUAPStatus,
      hBadgeForUAPStatus,
      hBadgeForUnitPosition,
      friendlyDate,

      pdUapRoute,
      leavesOrLeft,
    };
  },
});
