import { defineComponent, computed } from "@vue/composition-api";
import ShowPartial from "@/components/UnitApplicationPlacements/ShowPartial.vue";
import { NamedRts } from "@/router";

import { UnitApplicationPlacementForDisplay } from "@/controllers/unitApplicationPlacements";
import LoadingIndicator from "@/components/Loading/Loading.vue";

import {
  unitApplicationPlacements,
  unitApplicationPlacementsLoaded,
  unitApplicationReplacements,
  unitApplicationReplaces,
  replacementsAndReplacesLoaded,
} from "@/controllers/personDashboard";
import { PersonUnitDataItem } from "../PersonUnitData/person-unit-data";
import PinUnitApplicationPlacement from "@/components/PinUnitApplicationPlacement/PinUnitApplicationPlacement.vue";
import UnitApplicationCancelationSpot from "@/components/UnitApplicationCancelationSpot/UnitApplicationCancelationSpot.vue";

export default defineComponent({
  name: "personDashboardUnitApplicationPlacement",
  components: {
    LoadingIndicator,
    PinUnitApplicationPlacement,
    UnitApplicationCancelationSpot,
    ShowPartial,
  },

  props: {
    unitApplicationPlacementId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const placement = computed(() => {
      return unitApplicationPlacements.value.find(item => {
        return item.id === Number(props.unitApplicationPlacementId);
      });
    });

    const replaces = computed(() => {
      return unitApplicationReplaces.value.find(item => {
        return item.id === placement.value?.replacesId;
      });
    });

    const replacements = computed(() => {
      return unitApplicationReplacements.value.filter(item => {
        return (item.replacesId = Number(props.unitApplicationPlacementId));
      });
    });

    // Person Dashboard Unit Application Placement Route
    // rerouting them through the other component so
    // the data loads correctly
    const pdUapRoute = (placement: UnitApplicationPlacementForDisplay) => {
      return {
        name: NamedRts.personUnitData,
        params: {
          itemType: PersonUnitDataItem.unitApplicationPlacement,
          itemId: placement.id,
        },
      };
    };

    const uapFinderRoute = (id: number) => {
      return {
        name: NamedRts.unitFindReplacementApplications,
        params: {
          unitApplicationPlacementId: id.toString(),
        },
      };
    };

    return {
      pdUapRoute,
      uapFinderRoute,

      unitApplicationPlacementsLoaded,
      replacementsAndReplacesLoaded,
      placement,
      replaces,
      replacements,
    };
  },
});
