import {
  UnitApplicationCancelationSpot,
  emptyUnitApplicationCancelationSpot,
} from "@/controllers/unitApplicationCancelationSpots";

interface uaCancelationSpotsState {
  unitApplicationCancelationSpots: UnitApplicationCancelationSpot[];
  unitApplicationCancelationSpot: UnitApplicationCancelationSpot;
}

const state: uaCancelationSpotsState = {
  unitApplicationCancelationSpots: [],
  unitApplicationCancelationSpot: emptyUnitApplicationCancelationSpot(),
};

const mutations = {
  LOAD_UNIT_APPLICATION_CANCELATION_SPOTS(
    state: uaCancelationSpotsState,
    payload: UnitApplicationCancelationSpot[],
  ) {
    state.unitApplicationCancelationSpots = payload;
  },
  LOAD_UNIT_APPLICATION_CANCELATION_SPOT(
    state: uaCancelationSpotsState,
    payload: UnitApplicationCancelationSpot,
  ) {
    state.unitApplicationCancelationSpot = payload;
  },
};

const actions = {
  loadUnitApplicationCancelationSpots(
    context: any,
    items: UnitApplicationCancelationSpot[],
  ) {
    return new Promise(resolve => {
      context.commit("LOAD_UNIT_APPLICATION_CANCELATION_SPOTS", items);
      resolve(items);
    });
  },
  loadUnitApplicationCancelationSpot(
    context: any,
    items: UnitApplicationCancelationSpot,
  ) {
    return new Promise(resolve => {
      context.commit("LOAD_UNIT_APPLICATION_CANCELATION_SPOT", items);
      resolve(items);
    });
  },
};

const getters = {
  unitApplicationCancelationSpots: (state: uaCancelationSpotsState) => {
    return state.unitApplicationCancelationSpots;
  },
  unitApplicationCancelationSpot: (state: uaCancelationSpotsState) => {
    return state.unitApplicationCancelationSpot;
  },
};

const UnitApplicationCancelationSpotsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default UnitApplicationCancelationSpotsModule;
