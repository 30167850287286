import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from "@vue/composition-api";
import { NamedRts } from "@/router";
import { SMMode } from "@/store/sidebarMenu";
import { StoreActions } from "@/store";

export default defineComponent({
  name: "unitFindReplacementDashboard",

  setup(_props, ctx) {
    const $store = ctx.root.$store;
    const dataLoaded = ref(false);

    const isAdmin = computed(() => {
      return $store.getters.isAdmin;
    });

    // sidebar menu code
    const sidebarMenuClassObject = computed(() => {
      return $store.getters.sidebarMenuClassObject;
    });

    const dataColClassObject = computed(() => {
      return $store.getters.dataColClassObject;
    });

    const showMenu = () => {
      $store.dispatch(StoreActions.setSmMode, SMMode.menu);
    };

    const showData = () => {
      $store.dispatch(StoreActions.setSmMode, SMMode.data);
    };

    const dashboardLinkClasses = (rtName: any) => {
      if (rtName === ctx.root.$route.name) {
        return {
          active: true,
        };
      } else {
        return {
          active: false,
        };
      }
    };

    const routeParams = {
      recruiterWorkInProgress: {
        name: NamedRts.recruiterWorkInProgress,
      },
      placementsNotFollowing: {
        name: NamedRts.unitFindReplacementPlacementsNotFollowing,
      },
      cancelationSpotList: {
        name: NamedRts.unitFindReplacementCancelationSpotList,
      },
      applications: {
        name: NamedRts.unitFindReplacementApplications,
      },

      applicationsSimilarUnit: {
        name: NamedRts.unitFindReplacementApplicationsSimilarUnit,
      },

      applicationsOther: {
        name: NamedRts.unitFindReplacementApplicationsOther,
      },

      applicationsUnmatched: {
        name: NamedRts.unitFindReplacementApplicationsUnmatched,
      },
    };

    onMounted(() => {
      dataLoaded.value = true;
    });

    return {
      showMenu,
      showData,
      sidebarMenuClassObject,
      dataColClassObject,

      dashboardLinkClasses,

      isAdmin,
      dataLoaded,
      routeParams,
    };
  },
});
